import React from 'react';

import NodesInput, { NodesInputProps } from './NodesInput';
import { TopologyItem } from '../../components/Topology/TopologyItem';
import useCheckHost from '../hooks/useCheckHost';
import TypographyText from '../TypographyText';
import SpaceDescriptions from '../Layout/SpaceDescriptions';
import { StatusFormatStatus } from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import { CcClusterType } from '../../services/models/CcCluster';
import { Space } from 'antd';

export default SshCheckableNodesInput;

export type SshCheckableNodesInputProps = NodesInputProps & {
    clusterId?: number;
    clusterType?: CcClusterType;
    sshKeyPath?: string;
    sshPort?: number;
    sshUser?: string;
    sshSudoPassword?: string;
    checkSelectedHost?: boolean;
};

function SshCheckableNodesInput({
    clusterId,
    clusterType,
    sshKeyPath,
    sshPort,
    sshUser,
    sshSudoPassword,
    checkSelectedHost = true,
    validateItem,
    mutateItem,
    formProps,
    ...rest
}: SshCheckableNodesInputProps) {
    const { check, cancel } = useCheckHost({
        ...(sshUser && (sshSudoPassword || sshKeyPath)
            ? {
                  sshConfig: {
                      sshKeyPath,
                      sshPort,
                      sshUser,
                      sshSudoPassword,
                  },
              }
            : {}),
        clusterId,
        throwCancel: true,
    });
    return (
        <NodesInput
            validateItem={async (item: TopologyItem) => {
                try {
                    let newItem = (await validateItem?.(item)) || item;
                    if (
                        !checkSelectedHost &&
                        isAutoCompleteEnabled() &&
                        isItemInAutoComplete(newItem)
                    ) {
                        return newItem;
                    }

                    await cancel({
                        requestId: `check-host-${item.key}`,
                    });

                    const checkedHosts:any = await check(
                        [newItem.extraData.hostname],
                        {
                            requestId: `check-host-${newItem.key}`,
                        }
                    );
                    const checkedHost = checkedHosts?.[0] || {};
                    if (!checkedHost) {
                        throw new Error('No host has been checked');
                    }

                    if (checkedHost.status.error_code !== 'HostIsOk') {
                        throw new Error(
                            `${checkedHost.status?.message || ''} ${
                                checkedHost.status?.details || ''
                            } ${checkedHost.status?.advice || ''}`
                        );
                    }

                    newItem.status = StatusFormatStatus.success;
                    newItem.extraData = {
                        ...newItem.extraData,
                        checkedHost: checkedHost,
                    };
                    return newItem;
                } catch (error) {
                    throw error;
                }
            }}
            mutateItem={(item: TopologyItem) => {
                return {
                    ...(mutateItem ? mutateItem(item) : item),
                    title: (
                        <Space size={2} style={{minWidth: 0}}>
                            <TypographyText
                                ellipsis={{
                                    tooltip: item.title,
                                }}
                            >
                                {item.title}
                            </TypographyText>

                            {item.status === StatusFormatStatus.success && (
                                <InfoIcon
                                    info={getSshCheckeableHostInfo(
                                        item.extraData.checkedHost
                                    )}
                                />
                            )}
                        </Space>
                    ),
                };
            }}
            onItemDeleting={async (item: TopologyItem) => {
                await cancel({
                    requestId: `check-host-${item.key}`,
                });
            }}
            formProps={formProps}
            {...rest}
        />
    );

    function isAutoCompleteEnabled() {
        return (
            formProps?.primaryInputProps?.enableAutocomplete === true ||
            formProps?.secondaryInputProps?.enableAutocomplete === true
        );
    }

    function isItemInAutoComplete(item: TopologyItem) {
        return (
            formProps?.primaryInputProps?.autocompleteOptions?.find(
                (option) => option.value === item.extraData.hostname
            ) ||
            formProps?.secondaryInputProps?.autocompleteOptions?.find(
                (option) => option.value === item.extraData.hostname
            )
        );
    }
}

export function getSshCheckeableHostInfo(checkedHost: any) {
    return (
        <SpaceDescriptions
            wrap
            align="normal"
            direction="vertical"
            size={0}
            style={{ paddingBottom: 10 }}
        >
            <TypographyText style={{ color: 'white' }} strong>
                Host details
            </TypographyText>
            <SpaceDescriptions.Item
                label="CPU"
                labelStrong
                labelStyle={{ color: 'white' }}
            >
                {`${checkedHost?.hardware?.cpu.total_cpus} / cores ${checkedHost?.hardware?.cpu.total_cores}`}
            </SpaceDescriptions.Item>
            <SpaceDescriptions.Item
                label="Memory"
                labelStrong
                labelStyle={{ color: 'white' }}
                style={{ whiteSpace: 'nowrap' }}
                direction="horizontal"
            >
                {`free ${checkedHost?.hardware?.memory.memory_free_mb} / total ${checkedHost?.hardware?.memory.memory_total_mb} (MB)`}
            </SpaceDescriptions.Item>
            <SpaceDescriptions.Item
                label="OS"
                labelStrong
                labelStyle={{ color: 'white' }}
                style={{ whiteSpace: 'nowrap' }}
                direction="horizontal"
            >
                {[
                    checkedHost?.software?.os_version.name,
                    checkedHost?.software?.os_version.type,
                    checkedHost?.software?.os_version.release,
                ].join(', ')}
            </SpaceDescriptions.Item>
        </SpaceDescriptions>
    );
}
