import { useCallback, useEffect, useRef, useState } from 'react';
import { AppState, AppStateGlobalFilters } from '../appReducer';
import { useSelector } from 'react-redux';
import { clearGlobalFilters, getFiltersForFetch } from './GlobalFilter';
import useFetch, {
    FetchRefreshFunctionParams,
    UseFetchProps,
    UseFetchReturn,
} from './useFetch';
import { collectionMergeByKey } from './filtering';
type ListFetchParams = {
    page?: number;
    pageSize?: number;
    order?: any;
    filters?: any;
};
export type ListFetchRefreshFunctionParams = FetchRefreshFunctionParams &
    ListFetchParams;

export type UseListFetchProps = UseFetchProps & ListFetchParams;

export type UseListFetchData<T> = {
    list: T[];
    total: number;
};

export type UseListFetchReturn<T> = Omit<
    UseFetchReturn<UseListFetchData<T>>,
    'refresh' | 'data' | 'params'
> & {
    refresh: (
        params: FetchRefreshFunctionParams
    ) => Promise<UseListFetchData<T>>;
    list: T[] | null;
    total: number | null;
    pageSize: number;
    page: number;
};

export default function useListFetch<T>({
    name,
    fetchFn,
    cancelFn,
    ...rest
}: UseListFetchProps): UseListFetchReturn<T> {
    const {
        error,
        loading,
        loaded,
        data,
        params,
        refresh: refreshFetch,
        cancel,
        requestId,
        stopAutoRefresh,
    } = useFetch<UseListFetchData<T>>({
        name,
        fetchFn,
        cancelFn,
        ...rest,
    });
    const [list, setList] = useState<T[] | null>(null);
    const [total, setTotal] = useState<number | null>(null);
    const [globalFilters]: [
        AppStateGlobalFilters
    ] = useSelector(({ globalFilters }: AppState) => [globalFilters]);

    const gFilters = useRef<any>([]);

    const refresh = useCallback(
        async ({ filters, page, pageSize, order, ...rest } = {}) => {
            const {
                page: listPage,
                pageSize: listPageSize,
                order: listOrder,
                filters: listFilters,
            } = params || {};

            const newListFilters = collectionMergeByKey(
                'key',
                filters || clearGlobalFilters(listFilters) || [],
                gFilters.current
            );
            return await refreshFetch({
                ...rest,
                pageSize:
                    // pageSize = 0 was evaulated to false and it needs to be supported
                    (pageSize || listPageSize) === undefined
                        ? 10
                        : pageSize || listPageSize,
                page: page || listPage || 1,
                order: order || listOrder || undefined,
                filters:
                    newListFilters && newListFilters.length > 0
                        ? newListFilters
                        : undefined,
            });
        },
        [gFilters.current, params, refreshFetch]
    );

    useEffect(() => {
        if (data) {
            const { list, total } = data;
            // we need a copy to force updates
            setList(list);
            setTotal(total);
        }
    }, [data]);

    useEffect(() => {
        gFilters.current = getFiltersForFetch(globalFilters);
        if (loaded && globalFilters) {
            refresh({ page: 1 });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalFilters]);

    return {
        requestId,
        error,
        loading,
        loaded,
        list,
        refresh,
        total,
        cancel,
        stopAutoRefresh,
        ...params,
    };
}
