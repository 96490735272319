import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    AppState,
    AppStateJobStatsRecord,
    AppStateRunningJobs,
    setJobStats,
    setRunningJobs,
} from '../../appReducer';
import Immutable from 'immutable';
import { arrayFilter, arrayPages } from '../../common/filtering';
import useListFetch from '../../common/useListFetch';

import {
    initialListState,
    listReducer,
    setListAll,
} from '../../common/listReducer';
import { getSortAlphabeticFn } from '../../common/sorting';
import { FetchRefreshFunctionParams } from '../../common/useFetch';
import CmonJobsService from '../../services/cmon/CmonJobsService';
import CcJob from '../../services/models/CcJob';
import CcJobStats from '../../services/models/CcJobStats';

type ListParams = {
    page?: number;
    pageSize?: number;
    order?: (a: any, b: any) => number;
    filters?: Function[];
};

type FilterFunctionParams = ListParams & { arr?: any[] };

type RefreshFunctionParams = FetchRefreshFunctionParams;

export type UseRunningJobListProps = {
    name?: string | null;
    useGlobalState?: boolean;
    useCache?: boolean;
};
export default function useRunningJobList({
    name,
    useGlobalState = true,
    useCache = false,
}: UseRunningJobListProps = {}) {
    const {
        error,
        loading,
        loaded,
        list: runningJobs,
        refresh: refreshFetch,
        cancel,
    } = useListFetch({
        name,
        useCache,
        fetchFn: async (params, opts) => {
            const { jobs, total } = await CmonJobsService.getJobInstances(
                {
                    ascending: true,
                    show_defined: true,
                    show_running: true,
                    show_scheduled: false,
                    show_aborted: false,
                    show_finished: false,
                    show_failed: false,
                    ...params,
                },
                opts
            );
            return {
                list: jobs || [],
                total: total,
            };
        },
        cancelFn: async ({ requestId }) => {
            await CmonJobsService.cancelRequest(requestId);
        },
    });
    const [storedJobStats, storedRunningJobs]: [
        AppStateJobStatsRecord,
        AppStateRunningJobs
    ] = useSelector(({ jobStats, runningJobs }: AppState) => [
        jobStats,
        runningJobs,
    ]);
    const [list, setList] = useState<CcJob[]>(
        storedRunningJobs.toList().toArray()
    );
    const [statsRecord, setStatsRecord] = useState<AppStateJobStatsRecord>();
    const [total, setTotal] = useState<number>();
    const dispatch = useDispatch();
    const [
        {
            page: listPage,
            pageSize: listPageSize,
            order: listOrder,
            filters: listFilters,
        },
        listDispatch,
    ] = useReducer(listReducer, {
        ...initialListState,
        order: getSortAlphabeticFn('descend', (x) => x.created),
    });

    const filter = useCallback<(p?: FilterFunctionParams) => void>(
        ({
            page = listPage,
            pageSize = listPageSize,
            order = listOrder,
            filters = listFilters,
            arr = (storedRunningJobs && storedRunningJobs.toList().toArray()) ||
                [],
        } = {}) => {
            listDispatch(setListAll({ page, pageSize, order, filters }));
            const filteredArr = arrayFilter({ filters, arr });
            setList(arrayPages({ page, pageSize, order, arr: filteredArr }));
            setTotal(filteredArr.length);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [listPage, listPageSize, listOrder, listFilters, storedRunningJobs]
    );

    const refresh = useCallback<(p?: RefreshFunctionParams) => Promise<void>>(
        async ({
            page = listPage,
            pageSize = listPageSize,
            order = listOrder,
            filters = listFilters,
            ...rest
        } = {}) => {
            listDispatch(setListAll({ page, pageSize, order, filters }));
            await refreshFetch({ ...rest });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useEffect(() => {
        if (runningJobs) {
            const status = new CcJobStats({
                jobCount: { RUNNING: runningJobs.length },
            });
            setStatsRecord(status);
            if (useGlobalState) {
                dispatch(setJobStats(status));
                dispatch(
                    setRunningJobs(
                        Immutable.Map(
                            runningJobs.map((c: CcJob) => [
                                c.getCommandClusterKey(),
                                c,
                            ])
                        )
                    )
                );
            }
            filter({
                arr: runningJobs,
            });
        }
    }, [useGlobalState, runningJobs]);

    useEffect(() => {
        if (useGlobalState && storedRunningJobs) {
            filter();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useGlobalState, storedRunningJobs]);

    useEffect(() => {
        if (useGlobalState && storedJobStats) {
            setStatsRecord(storedJobStats);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useGlobalState, storedJobStats]);
    return {
        error,
        loading,
        loaded,
        statsRecord,
        list,
        refresh,
        total,
        filter,
        cancel,
        page: listPage,
        pageSize: listPageSize,
    };
}

type UseStateRunningJobsProps = {
    filters?: Function[];
};

export function useStateRunningJobsStats({
    filters,
}: UseStateRunningJobsProps) {
    const runningJobs: AppStateRunningJobs = useSelector(
        ({ runningJobs }: AppState) => runningJobs
    );
    return useMemo(() => {
        const arr = runningJobs?.valueSeq().toArray() || [];
        const filtered = arrayFilter({ filters, arr });

        return new CcJobStats({
            jobCount: { RUNNING: filtered.length },
        });
    }, [runningJobs]);
}
