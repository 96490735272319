import CmonClusterInfo, {
    CmonClusterInfoProps,
} from '../cmon/models/CmonClusterInfo';
import CcNode, {
    CcNodeProps,
    CcNodeRole,
    CcNodeType,
    getDatabaseNodeTypes,
} from './CcNode';
import { groupCollectionBy } from '../../common/utils/aggregating';
import CmonAlarmStatistics, {
    CmonAlarmStatisticsProps,
} from '../cmon/models/CmonAlarmStatistics';
import CcMySqlNode from './CcMySqlNode';
import CcGaleraNode from './CcGaleraNode';
import CcPrometheusNode from './CcPrometheusNode';
import CcProxySqlNode from './CcProxySqlNode';
import CcRedisNode from './CcRedisNode';
import CcRedisSentinelNode from './CcRedisSentinelNode';
import CcPostgreSqlNode from './CcPostgreSqlNode';
import CcHaProxyNode from './CcHaProxyNode';
import CcMongoNode, { CcMongoNodeMemberRole } from './CcMongoNode';
import { groupArrayBy } from '../../common/helpers';
import CcHost, { CcHostProps } from './CcHost';
import CcMsSqlNode from './CcMsSqlNode';
import { collectionKeyValueSort } from '../../common/sorting';
import CcElasticNode from './CcElasticNode';
import CcKeepalivedNode from './CcKeepalivedNode';
import CcNodeReplicationSlave from './CcNodeReplicationSlave';
import CcAgentNode from './CcAgentNode';

export enum CcClusterState {
    CLUSTER_MGMD_NO_CONTACT = 'MGMD_NO_CONTACT',
    CLUSTER_STARTED = 'STARTED',
    CLUSTER_NOT_STARTED = 'NOT_STARTED',
    CLUSTER_DEGRADED = 'DEGRADED',
    CLUSTER_FAILURE = 'FAILURE',
    CLUSTER_SHUTTING_DOWN = 'SHUTTING_DOWN',
    CLUSTER_RECOVERING = 'RECOVERING',
    CLUSTER_STARTING = 'STARTING',
    CLUSTER_UNKNOWN = 'UNKNOWN',
    CLUSTER_STOPPED = 'STOPPED',
}

export enum CcClusterTechnology {
    TECHNOLOGY_MYSQL = 'mysql',
    TECHNOLOGY_POSTGRESQL = 'postgresql',
    TECHNOLOGY_MONGODB = 'mongodb',
    TECHNOLOGY_REDIS = 'redis',
    TECHNOLOGY_MICROSOFT = 'microsoft',
    TECHNOLOGY_ELASTIC = 'elastic',
    TECHNOLOGY_UNKNOWN = 'unknown',
}

export enum CcClusterBase {
    BASE_POSTGRESQL = 'postgresql',
    BASE_MONGODB = 'mongodb',
    BASE_UNKNOWN = 'unknown',
}

export enum CcClusterVendor {
    TEN_GEN = '10gen',
    CODERSHIP = 'codership',
    MARIADB = 'mariadb',
    ORACLE = 'oracle',
    PERCONA = 'percona',
    REDIS = 'redis',
    ELASTICSEARCH = 'elasticsearch',
    MICROSOFT = 'microsoft',
    POSTGRESQL = 'postgresql',
}

export enum CcClusterType {
    TYPE_REPLICATION = 'REPLICATION',
    TYPE_GALERA = 'GALERA',
    TYPE_MYSQL_SINGLE = 'MYSQL_SINGLE',
    TYPE_MYSQL_CLUSTER = 'MYSQLCLUSTER', // NDB
    TYPE_MONGODB = 'MONGODB',
    TYPE_POSTGRESQL = 'POSTGRESQL_SINGLE',
    TYPE_TIMESCALEDB = 'TIMESCALEDB',
    TYPE_GROUP_REPLICATION = 'GROUP_REPLICATION',
    TYPE_REDIS = 'REDIS',
    TYPE_MSSQL_SINGLE = 'MSSQL_SINGLE',
    TYPE_MSSQL_AO_ASYNC = 'MSSQL_AO_ASYNC',
    TYPE_ELASTIC = 'ELASTIC',

    // specific for frontend logic (not present in backend)
    TYPE_MONGODB_SHARDS = 'MONGODB_SHARDS',
}

export type CcClusterProps = CmonClusterInfoProps & {
    alarm_statistics?: CmonAlarmStatisticsProps;
    state?: CcClusterState;
    hosts?: CcNodeProps[];
    tags?: string[];
    deployment_job_id?: number;
};

export default class CcCluster extends CmonClusterInfo {
    public readonly originalClusterType: CcClusterType;
    public readonly clusterType: CcClusterType;
    public readonly deploymentJobId?: number;

    public readonly controllerId: string = ''; // @todo remove
    public readonly controllerUrl: string = ''; // @todo remove
    public readonly state?: CcClusterState;
    public readonly alarmStat: CmonAlarmStatistics;
    public readonly nodes: CcNode[];
    public readonly primaryNode?: CcNode;
    public readonly tagList: string[];
    public readonly masterId?: number;
    public replicationPrimaries: CcCluster[];
    public replicationSecondaries: CcCluster[];

    public hasDbNodes?: boolean;
    public hasLoadBalancers?: boolean;
    public readonly nodeTypes: CcNodeType[];

    constructor(props: CcClusterProps) {
        // @todo Fix property 'tags' type in sdk, it shouldn't be string but array
        super({ ...props, tags: props.tags?.join(', ') || '' });

        this.originalClusterType = props.cluster_type as CcClusterType;
        // override
        this.clusterType = props.cluster_type as CcClusterType;
        /////

        this.alarmStat = new CmonAlarmStatistics(props.alarm_statistics || {});
        this.state = props.state;
        this.nodeTypes = [];
        this.nodes = props.hosts
            ? props.hosts.map((h: CcNodeProps) => {
                  h = { ...h, vendor: this.vendor as CcClusterVendor };
                  // @TODO: fix generator for this
                  let newNode: CcNode;
                  switch (h.nodetype) {
                      case CcNodeType.MYSQL:
                          newNode = new CcMySqlNode(h);
                          break;
                      case CcNodeType.GALERA:
                          newNode = new CcGaleraNode(h);
                          break;
                      case CcNodeType.PROMETHEUS:
                          newNode = new CcPrometheusNode(h);
                          break;
                      case CcNodeType.PROXYSQL:
                          newNode = new CcProxySqlNode(h);
                          break;
                      case CcNodeType.REDIS:
                          newNode = new CcRedisNode(h);
                          break;
                      case CcNodeType.REDIS_SENTINEL:
                          newNode = new CcRedisSentinelNode(h);
                          break;
                      case CcNodeType.TIMESCALEDB:
                      case CcNodeType.POSTGRESQL:
                          newNode = new CcPostgreSqlNode(h);
                          break;
                      case CcNodeType.HAPROXY:
                          newNode = new CcHaProxyNode(h);
                          break;
                      case CcNodeType.KEEPALIVED:
                          newNode = new CcKeepalivedNode(h);
                          break;
                      case CcNodeType.MONGO:
                          newNode = new CcMongoNode(h);
                          break;
                      case CcNodeType.MSSQL:
                          newNode = new CcMsSqlNode(h);
                          break;
                      case CcNodeType.ELASTIC:
                          newNode = new CcElasticNode(h);
                          break;
                      case CcNodeType.CMON_AGENT:
                          newNode = new CcAgentNode(h);
                          break;
                      default:
                          newNode = new CcNode(h);
                          break;
                  }
                  if (!this.hasDbNodes && newNode.isDatabaseNode()) {
                      this.hasDbNodes = true;
                  }
                  if (!this.hasLoadBalancers && newNode.isLoadBalancer()) {
                      this.hasLoadBalancers = true;
                  }
                  if (
                      !this.nodeTypes.includes(newNode.nodetype as CcNodeType)
                  ) {
                      this.nodeTypes.push(newNode.nodetype as CcNodeType);
                  }
                  return newNode;
              })
            : [];
        this.primaryNode = this.getNodesByRoles([
            CcNodeRole.PRIMARY,
            CcNodeRole.MASTER,
        ])[0];

        this.tagList = props.tags || [];
        this.replicationSecondaries = [];
        this.replicationPrimaries = [];

        if (
            this.isType(CcClusterType.TYPE_REPLICATION) ||
            this.isType(CcClusterType.TYPE_GALERA)
        ) {
            // TODO: remove this when backend supports 'intermediate' role
            this.nodes = this.nodes.map((n) => {
                // hack to set intermediate role
                if (n.isRole(CcNodeRole.MULTI)) {
                    const master = this.getPrimaryOf(n);
                    if (master) {
                        const isPrimaryOfItself = this.isPrimaryOf(n, n, true);
                        if (
                            !(
                                isPrimaryOfItself &&
                                master.isRole(CcNodeRole.MULTI)
                            )
                        ) {
                            n.setRole(CcNodeRole.INTERMEDIATE);
                        }
                    }
                }
                return n;
            });
        }

        // clusterType is arriving as postgresql here
        // @todo hopefully once it will be fixed on backend then we can remove this workaround
        // it is timescale db when the timescale db extension is found
        if (
            this.clusterType === CcClusterType.TYPE_POSTGRESQL &&
            this.nodes.find((node) => node.isType(CcNodeType.TIMESCALEDB))
        ) {
            this.clusterType = CcClusterType.TYPE_TIMESCALEDB;
        }

        // clusterType is arriving as mongodb here
        // @todo hopefully once it will be fixed on backend then we can remove this workaround
        // it is mongo shards when there is at least 1 router/mongos and 1 config server
        if (
            this.clusterType === CcClusterType.TYPE_MONGODB &&
            this.containsNodeTypeRole(
                CcNodeType.MONGO,
                CcNodeRole.MONGO_MONGOS
            ) &&
            this.containsNodeTypeRole(
                CcNodeType.MONGO,
                CcNodeRole.MONGO_CONFIG_SERVER
            )
        ) {
            this.clusterType = CcClusterType.TYPE_MONGODB_SHARDS;
        }
        this.deploymentJobId = props.deployment_job_id;
    }

    public getKey(): string {
        return `${this.clusterId}`;
    }

    public getTechnology(): CcClusterTechnology {
        return getTechnology(this.clusterType);
    }

    /**
     * Clusters with same base are expecting to be represented similarly in UI
     * e.g TYPE_POSTGRESQL and TYPE_TIMESCALEDB
     */
    public getBase(): CcClusterBase {
        return getBase(this.clusterType);
    }

    public setReplicationPrimaries(clusters: CcCluster[]) {
        this.replicationPrimaries = clusters;
    }

    public setReplicationSecondaries(clusters: CcCluster[]) {
        this.replicationSecondaries = clusters;
    }

    public getNodesByType(nodeType: CcNodeType) {
        return this.nodes.filter((n) => n.nodetype === nodeType);
    }

    public getNodesByRoles(nodeRoles: CcNodeRole[]) {
        return this.nodes.filter((n) =>
            nodeRoles.includes(n.role as CcNodeRole)
        );
    }

    public getNodesByTypesAndRoles(
        nodeTypes: CcNodeType[],
        nodeRoles: CcNodeRole[]
    ) {
        return this.nodes.filter(
            (n) =>
                nodeTypes.includes(n.nodetype as CcNodeType) &&
                nodeRoles.includes(n.role as CcNodeRole)
        );
    }

    public getNodesGroupedByType(): { [key in CcNodeType]: CcNode[] } {
        return groupCollectionBy(this.nodes, 'nodetype');
    }

    public getNodesGroupedByTypeWithNoneRole(): {
        [key in CcNodeType]: CcNode[];
    } {
        return groupCollectionBy(
            this.nodes.filter((n) => n.isRole(CcNodeRole.NONE) || n.isType(CcNodeType.KEEPALIVED)),
            'nodetype'
        );
    }

    public getDbNodesGroupedByRole(
        excludeNoneRole?: boolean
    ): {
        [key in CcNodeRole]: CcNode[];
    } {
        const nodes = excludeNoneRole
            ? this.getDatabaseNodes().filter((n) => !n.isRole(CcNodeRole.NONE))
            : this.getDatabaseNodes();
        return groupCollectionBy(nodes, 'role');
    }

    public getDatabaseNodes(): CcNode[] {
        return collectionKeyValueSort(
            this.nodes.filter((node) =>
                node.isDatabaseNode([CcNodeRole.MONGO_CONFIG_SERVER])
            ),
            'role',
            [
                CcNodeRole.MASTER,
                CcNodeRole.PRIMARY,
                CcNodeRole.SLAVE,
                CcNodeRole.SECONDARY,
            ]
        ) as CcNode[];
    }

    public getPrometheusNode() {
        return this.nodes.find((node) => {
            return node.nodetype === CcNodeType.PROMETHEUS;
        }) as CcPrometheusNode;
    }

    public isMonitoringEnabled() {
        return !!this.getPrometheusNode();
    }

    public getHosts() {
        return (Object.values(
            groupArrayBy(
                this.nodes.filter(
                    (node) => !node.isType(CcNodeType.CMON_AGENT)
                ),
                (node: CcNode) => node.hostname
            ) as { [key: string]: CcNode[] }
        ) as CcNode[][]).map(
            (nodes) =>
                new CcHost({
                    hostname: nodes[0].hostname || null, // id is the same accross nodes for same host so we pick first node,
                    types: nodes.map((n) => n.nodetype),
                    hostId: nodes[0].hostId || null, // id is the same accross nodes for same host so we pick first node
                    distribution: nodes[0].distribution || null, // distribution is the same accross nodes for same host so we pick first node
                    nodes: nodes,
                } as CcHostProps)
        );
    }

    public getControllerNode() {
        return this.nodes.find(
            (node) => node.nodetype === CcNodeType.CONTROLLER
        );
    }

    public getControllerHost() {
        return this.getHosts().find((host) =>
            host.hasNodeType(CcNodeType.CONTROLLER)
        );
    }

    public isTechnology(technology: CcClusterTechnology): boolean {
        return this.getTechnology() === technology;
    }

    public isBase(base: CcClusterBase): boolean {
        return this.getBase() === base;
    }

    public isType(type: CcClusterType | CcClusterType[]) {
        if (Array.isArray(type)) {
            return this.clusterType && type.includes(this.clusterType);
        }
        return this.clusterType === type;
    }

    public isVendor(vendor: CcClusterVendor) {
        return this.vendor === vendor;
    }

    public isVendorMariaDb() {
        return this.isVendor(CcClusterVendor.MARIADB);
    }

    public isVendorPercona() {
        return this.isVendor(CcClusterVendor.PERCONA);
    }

    public isMaintenanceModeEnabled() {
        return !!this.maintenanceModeActive;
    }

    public containsNodeType(type: CcNodeType) {
        return !!this.nodes.find((node) => node.nodetype === type);
    }

    public containsNodeRole(role: CcNodeRole) {
        return !!this.nodes.find((node) => node.role === role);
    }

    public containsNodeTypeRole(type: CcNodeType, role: CcNodeRole) {
        return !!this.nodes.find((n) => n.isType(type) && n.isRole(role));
    }

    public isMongoShard() {
        return (
            this.containsNodeRole(CcNodeRole.MONGO_CONFIG_SERVER) &&
            this.containsNodeRole(CcNodeRole.MONGO_MONGOS)
        );
    }

    /**
     * Returns true if every database node is readonly
     */
    public isReadonly() {
        return this.getDatabaseNodes().every((node) => node.readonly);
    }

    public getPrimaryOf(node: CcNode) {
        let master;
        if (
            this.isType(CcClusterType.TYPE_REPLICATION) ||
            this.isType(CcClusterType.TYPE_GALERA) ||
            this.isType(CcClusterType.TYPE_TIMESCALEDB) ||
            this.isType(CcClusterType.TYPE_POSTGRESQL) ||
            this.isType(CcClusterType.TYPE_REDIS) ||
            this.isType(CcClusterType.TYPE_MSSQL_SINGLE) ||
            this.isType(CcClusterType.TYPE_MSSQL_AO_ASYNC)
        ) {
            if (node.isType(CcNodeType.GALERA)) {
                master = this.nodes.find((m) => {
                    const galerPrimary = m as CcGaleraNode;
                    return (
                        galerPrimary.slaves &&
                        galerPrimary.slaves.includes(node.getHostWithPort())
                    );
                });
            } else {
                master = this.nodes.find((host) => {
                    return (
                        node.replicationSlave &&
                        [
                            host.hostname,
                            host.hostnameData,
                            host.hostnameInternal,
                        ].includes(node.replicationSlave.masterHost) &&
                        host.port === node.replicationSlave.masterPort
                    );
                });
            }
        } else if (this.isBase(CcClusterBase.BASE_MONGODB)) {
            master = this.nodes.find((host: CcNode) => {
                if (!host.isType(CcNodeType.MONGO)) {
                    return false;
                }
                const mongoHost = host as CcMongoNode;
                return (
                    node.hostname !== mongoHost.hostname &&
                    mongoHost.memberRole === CcMongoNodeMemberRole.PRIMARY &&
                    (node as CcMongoNode).rs === mongoHost.rs
                );
            });
        }
        return master;
    }

    public isPrimaryOf(masterNode: CcNode, childNode: CcNode, deep?: boolean) {
        if (deep) {
            let currentMaster = this.getPrimaryOf(childNode);
            let i = 0;
            const hosts = this.nodes;
            while (
                i < hosts.length &&
                currentMaster &&
                currentMaster.getKey() !== masterNode.getKey()
            ) {
                currentMaster = this.getPrimaryOf(currentMaster);
                i++;
            }
            if (i === hosts.length) {
                return false;
            } else {
                return true;
            }
        } else {
            const master = this.getPrimaryOf(childNode);
            return !!master && master.getKey() === masterNode.getKey();
        }
    }

    public isReplica() {
        return this.replicationPrimaries.length > 0;
    }

    public getReplicationPrimary() {
        return this.replicationPrimaries[0];
    }

    public getPrimaryCandidates(node?: CcNode, showAll?: boolean) {
        return this.nodes.filter((n) => {
            if (
                !showAll &&
                (node?.isReplicaOf(n) || node?.getKey() === n.getKey())
            ) {
                // filtering out current master of the node and the node itself.
                return false;
            }
            if (this.isType(CcClusterType.TYPE_GALERA)) {
                return (
                    n.isType(CcNodeType.GALERA) &&
                    (n as CcGaleraNode).isBinaryLogON()
                );
            } else if (this.isType(CcClusterType.TYPE_REPLICATION)) {
                return (
                    n.isType(CcNodeType.MYSQL) &&
                    (n as CcMySqlNode).isBinaryLogON()
                );
            } else if (
                this.isType([
                    CcClusterType.TYPE_POSTGRESQL,
                    CcClusterType.TYPE_TIMESCALEDB,
                ])
            ) {
                return (
                    n.isType([CcNodeType.POSTGRESQL, CcNodeType.TIMESCALEDB]) &&
                    n.isRole(CcNodeRole.MASTER)
                );
            } else if (this.isType(CcClusterType.TYPE_MSSQL_AO_ASYNC)) {
                return n.isType(CcNodeType.MSSQL) && n.isPrimary();
            } else {
                return false;
            }
        });
    }

    public getPrimaryNodes() {
        return this.nodes.filter((node: CcNode) => node.isPrimary());
    }

    public isReplicationBidirectional(cluster: CcCluster) {
        return isReplicationBidirectional(this, cluster);
    }
    public getClusterReplicationNode() {
        return getClusterReplicationNode(this);
    }

    public isRegularSSLSupported() {
        return isRegularSSLSupported(this);
    }

    public isReplicationSSLSupported() {
        return isReplicationSSLSupported(this);
    }

    public isRegularSSLEnabled() {
        return isRegularSSLEnabled(this);
    }

    public isDisablingRegularSSLSupported() {
        return isDisablingRegularSSLSupported(this);
    }

    public isReplicationSSLEnabled() {
        return isReplicationSSLEnabled(this);
    }

    public isAuditLogSupported() {
        return isAuditLogSupported(this);
    }

    public isAuditLogEnabled() {
        return isAuditLogEnabled(this);
    }

    public hasBackupTool() {
        if (this.isTechnology(CcClusterTechnology.TECHNOLOGY_POSTGRESQL)) {
            return !!this.getNodesByType(CcNodeType.PGBACKREST)?.length;
        }
        if (this.isTechnology(CcClusterTechnology.TECHNOLOGY_MONGODB)) {
            return !!this.getNodesByType(CcNodeType.PBM_AGENT)?.length;
        }
        return false;
    }

    public areStatsEnabled() {
        if (this.isTechnology(CcClusterTechnology.TECHNOLOGY_POSTGRESQL)) {
            return this.getDatabaseNodes().every((node) =>
                (node as CcPostgreSqlNode).isPgStatStatementsEnabled()
            );
        } else if (this.isTechnology(CcClusterTechnology.TECHNOLOGY_MYSQL)) {
            return this.getDatabaseNodes().every((node) =>
                (node as CcMySqlNode).isPerformanceSchemaEnabled()
            );
        } else {
            return false;
        }
    }
}

function getBase(clusterType?: CcClusterType | string) {
    switch (clusterType) {
        case CcClusterType.TYPE_TIMESCALEDB:
        case CcClusterType.TYPE_POSTGRESQL:
            return CcClusterBase.BASE_POSTGRESQL;
        case CcClusterType.TYPE_MONGODB:
        case CcClusterType.TYPE_MONGODB_SHARDS:
            return CcClusterBase.BASE_MONGODB;
        default:
            return CcClusterBase.BASE_UNKNOWN;
    }
}

export function getTechnology(clusterType?: CcClusterType | string) {
    let techType: CcClusterTechnology;
    switch (clusterType) {
        case CcClusterType.TYPE_REPLICATION:
        case CcClusterType.TYPE_GALERA:
        case CcClusterType.TYPE_MYSQL_SINGLE:
        case CcClusterType.TYPE_MYSQL_CLUSTER:
        case CcClusterType.TYPE_GROUP_REPLICATION:
            techType = CcClusterTechnology.TECHNOLOGY_MYSQL;
            break;
        case CcClusterType.TYPE_MONGODB_SHARDS:
        case CcClusterType.TYPE_MONGODB:
            techType = CcClusterTechnology.TECHNOLOGY_MONGODB;
            break;
        case CcClusterType.TYPE_POSTGRESQL:
        case CcClusterType.TYPE_TIMESCALEDB:
            techType = CcClusterTechnology.TECHNOLOGY_POSTGRESQL;
            break;
        case CcClusterType.TYPE_REDIS:
            techType = CcClusterTechnology.TECHNOLOGY_REDIS;
            break;
        case CcClusterType.TYPE_ELASTIC:
            techType = CcClusterTechnology.TECHNOLOGY_ELASTIC;
            break;
        case CcClusterType.TYPE_MSSQL_SINGLE:
        case CcClusterType.TYPE_MSSQL_AO_ASYNC:
            techType = CcClusterTechnology.TECHNOLOGY_MICROSOFT;
            break;
        default:
            techType = CcClusterTechnology.TECHNOLOGY_UNKNOWN;
    }
    return techType;
}

export function getClusterTypesByTechnology(
    technology: CcClusterTechnology | string
): (CcClusterType | string)[] {
    switch (technology) {
        case CcClusterTechnology.TECHNOLOGY_MYSQL:
            return [
                CcClusterType.TYPE_REPLICATION,
                CcClusterType.TYPE_GALERA,
                CcClusterType.TYPE_MYSQL_SINGLE,
                CcClusterType.TYPE_MYSQL_CLUSTER,
                CcClusterType.TYPE_GROUP_REPLICATION,
            ];

        case CcClusterTechnology.TECHNOLOGY_MONGODB:
            return [
                CcClusterType.TYPE_MONGODB,
                CcClusterType.TYPE_MONGODB_SHARDS,
            ];
        case CcClusterTechnology.TECHNOLOGY_POSTGRESQL:
            return [
                CcClusterType.TYPE_POSTGRESQL,
                CcClusterType.TYPE_TIMESCALEDB,
            ];
        case CcClusterTechnology.TECHNOLOGY_REDIS:
            return [CcClusterType.TYPE_REDIS];
        case CcClusterTechnology.TECHNOLOGY_MICROSOFT:
            return [
                CcClusterType.TYPE_MSSQL_SINGLE,
                CcClusterType.TYPE_MSSQL_AO_ASYNC,
            ];
        case CcClusterTechnology.TECHNOLOGY_ELASTIC:
            return [CcClusterType.TYPE_ELASTIC];
        default:
            return [];
    }
}

export function getNodeRolesByClusterType(clusterType: CcClusterType) {
    switch (clusterType) {
        case CcClusterType.TYPE_REDIS:
        case CcClusterType.TYPE_MYSQL_CLUSTER:
        case CcClusterType.TYPE_GALERA:
        case CcClusterType.TYPE_MONGODB:
        case CcClusterType.TYPE_POSTGRESQL:
        case CcClusterType.TYPE_REPLICATION:
        case CcClusterType.TYPE_GROUP_REPLICATION:
        case CcClusterType.TYPE_TIMESCALEDB:
        case CcClusterType.TYPE_MYSQL_SINGLE:
        default:
            return [
                CcNodeRole.SLAVE,
                CcNodeRole.MASTER,
                CcNodeRole.BVS,
                CcNodeRole.MONGO_DATA_NODE,
                CcNodeRole.MONGO_ARBITER,
                CcNodeRole.MONGO_CONFIG_SERVER,
                CcNodeRole.MONGO_MONGOS,
                CcNodeRole.MULTI,
                CcNodeRole.INTERMEDIATE,
            ];
    }
}

export function isReplicationBidirectional(
    cluster1: CcCluster,
    cluster2: CcCluster
) {
    return (
        !!cluster1.replicationSecondaries.find(
            (c) => c.clusterId === cluster2.clusterId
        ) &&
        !!cluster2.replicationSecondaries.find(
            (c) => c.clusterId === cluster1.clusterId
        )
    );
}

// Returns the node is being used for performing c2c replication
export function getClusterReplicationNode(
    cluster: CcCluster
): CcNode | undefined {
    const dbNode = cluster
        .getDatabaseNodes()
        .filter((n) => n.replicationSlave?.masterClusterId > 0)[0];
    return dbNode;
}

export function isRegularSSLEnabled(cluster: CcCluster) {
    if (isRegularSSLSupported(cluster)) {
        return cluster
            .getDatabaseNodes()
            .every(({ sslCerts }) => sslCerts?.server?.sslEnabled);
    } else {
        return false;
    }
}

export function isRegularSSLSupported(cluster: CcCluster) {
    return (
        cluster.isTechnology(CcClusterTechnology.TECHNOLOGY_MYSQL) ||
        cluster.isTechnology(CcClusterTechnology.TECHNOLOGY_POSTGRESQL) ||
        cluster.isTechnology(CcClusterTechnology.TECHNOLOGY_MONGODB)
    );
}

export function isDisablingRegularSSLSupported(cluster: CcCluster) {
    if (!isRegularSSLSupported(cluster)) {
        return false;
    } else if (cluster.isVendorPercona()) {
        return cluster.version ? parseFloat(cluster.version) < 5.7 : false;
    } else {
        return true;
    }
}

export function isReplicationSSLSupported(cluster: CcCluster) {
    return cluster.isType(CcClusterType.TYPE_GALERA);
}

export function isReplicationSSLEnabled(cluster: CcCluster) {
    if (isReplicationSSLSupported(cluster)) {
        return cluster
            .getDatabaseNodes()
            .every(
                ({ sslCerts }) =>
                    sslCerts?.replication?.key && sslCerts?.replication?.path
            );
    } else {
        return false;
    }
}

export function isAuditLogSupported(cluster: CcCluster) {
    if (cluster.isTechnology(CcClusterTechnology.TECHNOLOGY_MYSQL)) {
        return cluster.isVendorMariaDb();
    } else if (
        cluster.isTechnology(CcClusterTechnology.TECHNOLOGY_POSTGRESQL)
    ) {
        return true;
    }
    return false;
}

export function isAuditLogEnabled(cluster: CcCluster) {
    if (cluster.isTechnology(CcClusterTechnology.TECHNOLOGY_POSTGRESQL)) {
        return cluster.nodes.some(({ extensions }: any) => {
            if (extensions) {
                return (
                    extensions.filter(({ name }: any) => name === 'pgaudit')
                        .length > 0
                );
            }
            return false;
        });
    }
    return cluster.nodes.some(({ auditLog }: any) => {
        if (auditLog) {
            return auditLog.plugins
                .filter(
                    ({ name }: any) =>
                        name === 'audit_log' || name === 'SERVER_AUDIT'
                )
                .some(({ status }: any) => status === 'ACTIVE');
        } else {
            return false;
        }
    });
}
