import React, { useMemo } from 'react';
import CcNode, {
    CcNodeType,
    getLoadBalancerNodeTypes,
} from '../../../services/models/CcNode';
import CcCluster from '../../../services/models/CcCluster';
import AppTable, { AppTableProps } from '../../../common/DataDisplay/AppTable';
import StatusFormat, {
    StatusFormatStatus,
} from '@severalnines/bar-frontend-components/build/lib/Format/StatusFormat';
import { Checkbox, Form, Space, Tag, Tooltip } from 'antd';
import ModalDefaultForm from '../../../common/ModalDefaultForm';
import { useForm } from 'antd/lib/form/Form';
import TypographyText from '../../../common/TypographyText';
import FormFooter from '../../../common/FormFooter';
import useFetch from '../../../common/useFetch';
import CmonConfigService from '../../../services/cmon/CmonConfigService';
import CmonClustersService from '../../../services/cmon/CmonClustersService';
import CcPkgInfo from '../../../services/models/CcPkgInfo';
import { InfoCircleOutlined } from '@ant-design/icons';
import WrapFormat, { WrapFormatProps } from '../../../common/Format/WrapFormat';
import ClusterNodesCheckAvailablePackages from './ClusterNodesCheckAvailablePackages';
import useCreateJob from '../../Jobs/useCreateJob';
import { CmonJobInstanceCommand } from '../../../services/cmon/models/CmonJobInstance';
import NodeFormat, { getNodeHostWithDesc } from '../../Nodes/NodeFormat';
import { getNodeTypeText } from '../../Nodes/NodeTypeFormat';

const UPGRADABLE_DB_NODE_TYPES = [
    CcNodeType.MYSQL,
    CcNodeType.GALERA,
    CcNodeType.POSTGRESQL,
];
const UPGRADABLE_LOAD_BALANCER_NODE_TYPES = [
    ...getLoadBalancerNodeTypes(),
    CcNodeType.MEMCACHED,
];
export const CLUSTER_UPGRADABLE_NODE_TYPES = [
    ...UPGRADABLE_DB_NODE_TYPES,
    ...UPGRADABLE_LOAD_BALANCER_NODE_TYPES,
];

type RowRecord = {
    type: 'load_balancer' | 'db_node';
    name: string;
    packages?: CcPkgInfo[];
    node?: CcNode;
};

export default ClusterNodesUpgradeForm;
export type ClusterNodesUpgradeFormProps = {
    cluster: CcCluster;
    onCancel?: () => void;
    onSuccess?: () => void;
};

function ClusterNodesUpgradeForm({
    cluster,
    onCancel,
    onSuccess,
    ...rest
}: ClusterNodesUpgradeFormProps) {
    const [form] = useForm();

    const {
        loading: loadingPackages,
        refresh: refreshPackages,
        data,
    } = useFetch<any[]>({
        name: 'cluster-available-upgrades',
        useCache: true,
        autoFetch: true,
        fetchFn: async (params, opts) => {
            const { packages } = await CmonClustersService.availableUpgrades(
                {
                    cluster_id: cluster.clusterId,
                },
                opts
            );

            return packages;
        },
        cancelFn: async ({ requestId }) => {
            await CmonConfigService.cancelRequest(requestId);
        },
    });

    const { send: upgradeSend, loading: upgradeLoading } = useCreateJob({
        title: 'Upgrade packages',
        command: CmonJobInstanceCommand.UPGRADE_CLUSTER,
        clusterId: cluster.clusterId,
        onSuccess,
    });

    /**
     * collecting packages for each nodes
     * e.g.
     * {
     *     '127.0.0.1:3306': [...]
     * }
     */
    const availablePackages = useMemo(() => {
        if (data) {
            const hostMap = cluster.getHosts().reduce((map: any, host) => {
                map[host.hostname] = host;
                return map;
            }, {});
            return data.reduce((a: any, pkg: CcPkgInfo) => {
                const host =
                    (pkg.hostName && hostMap[pkg.hostName]) || undefined;
                if (host) {
                    host.nodes.forEach((node: CcNode) => {
                        if (node.isType(getPkgNodeTypes(pkg))) {
                            const key = node.getHostWithPort();
                            if (!a[key]) {
                                a[key] = [];
                            }
                            a[key].push(pkg);
                        }
                    });
                }
                return a;
            }, {});
        }
        return [];
    }, [data, cluster]);

    const dataSource: RowRecord[] = useMemo(() => {
        const data: RowRecord[] = [];
        const [lb, db] = cluster.nodes.reduce(
            ([lb, db]: CcNode[][], node: CcNode) => {
                if (node.isType(UPGRADABLE_LOAD_BALANCER_NODE_TYPES)) {
                    lb.push(node);
                } else if (node.isType(UPGRADABLE_DB_NODE_TYPES)) {
                    db.push(node);
                }
                return [lb, db];
            },
            [[], []]
        );

        const createRow = (props: RowRecord) => (node: CcNode): RowRecord => ({
            ...props,
            node,
            packages: availablePackages[node.getHostWithPort()],
        });
        if (lb.length > 0) {
            const header: RowRecord = {
                name: 'Load balancer',
                type: 'load_balancer',
            };
            data.push(header);
            data.push(...lb.map(createRow(header)));
        }
        if (db.length > 0) {
            const header: RowRecord = {
                name: 'Database nodes',
                type: 'db_node',
            };
            data.push(header);
            data.push(...db.map(createRow(header)));
        }
        return data;
    }, [cluster, availablePackages]);

    const columns: AppTableProps['columns'] = [
        {
            title: 'Node',
            key: 'node',
            render: (record: RowRecord) =>
                record.node ? (
                    getNodeHostWithDesc(record.node)
                ) : (
                    <TypographyText strong={true}>{record.name}</TypographyText>
                ),
            onCell: (record: RowRecord) => ({
                colSpan: record.node ? 1 : 4,
            }),
        },
        {
            title: 'Node info',
            key: 'info',
            render: (record: RowRecord) =>
                record.node && (
                    <NodeFormat
                        node={record.node}
                        showText={false}
                        extraRight={
                            <TypographyText>
                                {`${getNodeTypeText(
                                    record.node.nodetype
                                )} | ${record.node.distribution?.getFullName()}`}
                            </TypographyText>
                        }
                    />
                ),
            onCell: (record: RowRecord) => ({
                colSpan: record.node ? 1 : 0,
            }),
        },
        {
            title: 'Status',
            key: 'status',
            render: (record: RowRecord) => {
                if (loadingPackages) {
                    return '';
                }
                const newVersions =
                    record?.packages?.filter(
                        (pkg) => pkg.installedVersion !== pkg.availableVersion
                    ) || [];
                return newVersions.length > 0 ? (
                    <Space>
                        <StatusFormat status={StatusFormatStatus.warning}>
                            Upgrade available
                        </StatusFormat>
                        <PackagesTableWrapFormat
                            tableProps={{ packages: record.packages }}
                        />
                    </Space>
                ) : (
                    <StatusFormat status={StatusFormatStatus.success}>
                        Up to date
                    </StatusFormat>
                );
            },
            onCell: (record: RowRecord) => ({
                colSpan: record.node ? 1 : 0,
            }),
        },

        {
            title: 'Installed packages',
            key: 'installed',
            align: 'center',
            render: (record: RowRecord) => (
                <PackagesTableWrapFormat
                    tableProps={{
                        packages: record.packages,
                        showAvailable: false,
                    }}
                />
            ),
            onCell: (record: RowRecord) => ({
                colSpan: record.node ? 1 : 0,
            }),
        },
        {
            title: '',
            key: 'check',
            render: (record: CcNode, r: any, index: number) => (
                <Form.Item
                    name={['nodes', index]}
                    valuePropName="checked"
                    noStyle={true}
                >
                    <Checkbox />
                </Form.Item>
            ),
        },
    ];

    const handleCancel = () => {
        onCancel?.();
    };

    const handleValuesChange = ({ nodes }: any) => {
        const allSelected = form.getFieldValue('nodes');
        // assuming that selected will be only one node at a time, getting [0]
        const key = Object.keys(nodes).map((key) => +key)[0];
        const record = dataSource[key];
        const newSelected = { ...allSelected, ...nodes };
        // getting whole group of selected node
        const groupKeys = dataSource.reduce((a: number[], item, index) => {
            if (item.type === record.type) {
                a.push(index);
            }
            return a;
        }, []);

        // checking if selected row is a group row
        if (!record.node) {
            groupKeys.forEach((index) => {
                newSelected[index] = nodes[key];
            });
        } else {
            const selectedGroupKeys = groupKeys.filter(
                (key) => newSelected[key]
            );

            // if only one row from group is selected and it's a group row then deselect it
            if (
                selectedGroupKeys.length === 1 &&
                !dataSource[selectedGroupKeys[0]].node
            ) {
                newSelected[selectedGroupKeys[0]] = false;
            }
        }

        form.setFieldsValue({ nodes: newSelected });
    };

    const handleCheckUpgradesFinish = async () => {
        await refreshPackages({});
    };

    const handleRow = (record: RowRecord, index: number) => {
        return {
            'data-testid': `upgrade-type-table-row-${index}`,
            onClick: () => {
                const selected = form.getFieldValue(['nodes', index]);
                handleValuesChange({
                    nodes: {
                        [index]: !selected,
                    },
                });
            },
            style: { cursor: 'pointer' },
        };
    };

    const handleSubmit = async () => {
        const selectedNodes = form.getFieldValue('nodes') || {};
        const nodes = Object.entries(selectedNodes)
            .map(([key, value], index) => {
                if (value) {
                    return dataSource[key]?.node;
                }
                return undefined;
            })
            .filter((node) => !!node);

        if (nodes.length > 0) {
            await upgradeSend({
                clusterId: cluster.clusterId,
                exec_upgrade_script: true,
                force: true,
                nodes: nodes.map((node) => ({
                    hostname: node?.hostname,
                    port: node?.port,
                })),
            });
        }
    };

    return (
        <div className="ClusterNodesUpgradeForm">
            <ModalDefaultForm
                title="Upgrade nodes"
                width={900}
                form={form}
                onCancel={handleCancel}
                defaultVisible={true}
                bodyStyle={{ padding: '20px' }}
                loading={loadingPackages}
            >
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    onValuesChange={handleValuesChange}
                    layout="vertical"
                >
                    <AppTable
                        size="middle"
                        onRow={handleRow}
                        dataSource={dataSource}
                        columns={columns}
                    />
                    <Form.Item noStyle={true} shouldUpdate={true}>
                        {() => {
                            const hasSelected = Object.values(
                                form.getFieldValue('nodes') || {}
                            ).some((value) => value);
                            return (
                                <FormFooter
                                    noDivider={true}
                                    submitButtonText="Upgrade"
                                    submitButtonProps={{
                                        disabled: !hasSelected,
                                    }}
                                    showSubmitButton={true}
                                    onCancel={handleCancel}
                                    extraLeft={true}
                                    loading={upgradeLoading}
                                >
                                    <ClusterNodesCheckAvailablePackages
                                        cluster={cluster}
                                        onFinish={handleCheckUpgradesFinish}
                                    />
                                </FormFooter>
                            );
                        }}
                    </Form.Item>
                </Form>
            </ModalDefaultForm>
        </div>
    );
}

type PackagesTableProps = {
    packages?: CcPkgInfo[];
    showAvailable?: boolean;
};

function PackagesTable({ packages, showAvailable = true }: PackagesTableProps) {
    const newVersions = useMemo(
        () =>
            (showAvailable &&
                packages?.filter(
                    (pkg) => pkg.installedVersion !== pkg.availableVersion
                )) ||
            [],
        [packages]
    );

    const dataSource = newVersions.length ? newVersions : packages;

    const currentVersionColumn = {
        title: 'Current version',
        key: 'current',
        render: (record: CcPkgInfo) =>
            `${record.packageName} | ${record.installedVersion}`,
    };
    const newVersionColumn = {
        title: (
            <Space size={10}>
                <TypographyText>Available version</TypographyText>
                <Tag color="gold">NEW</Tag>
            </Space>
        ),
        key: 'available',
        render: (record: CcPkgInfo) =>
            `${record.packageName} | ${record.availableVersion}`,
    };
    const columns: AppTableProps['columns'] =
        newVersions.length > 0
            ? [currentVersionColumn, newVersionColumn]
            : [currentVersionColumn];

    return (
        <AppTable
            size="small"
            onRow={() => ({})}
            dataSource={dataSource}
            columns={columns}
        />
    );
}

type PackagesTableWrapFormatProps = WrapFormatProps & {
    tableProps?: PackagesTableProps;
};

function PackagesTableWrapFormat({
    tableProps = {},
    children,
    ...rest
}: PackagesTableWrapFormatProps) {
    return (
        <WrapFormat
            popoverProps={{
                destroyTooltipOnHide: true,
                overlayInnerStyle: {
                    minWidth: '240px',
                    padding: '5px',
                },
                mouseEnterDelay: 0,
                trigger: 'hover',
            }}
            popoverContent={<PackagesTable {...tableProps} />}
            {...rest}
        >
            {children || <InfoCircleOutlined />}
        </WrapFormat>
    );
}

function getPkgNodeTypes(pkg: CcPkgInfo) {
    switch (pkg.hostClassName) {
        case 'CmonPostgreSqlHost':
            return [CcNodeType.POSTGRESQL];
        case 'CmonMySqlHost':
            return [
                CcNodeType.MYSQL,
                CcNodeType.GALERA,
                CcNodeType.NDB,
                CcNodeType.NDB_MGMD,
            ];
        case 'CmonProxySqlHost':
            return [CcNodeType.PROXYSQL];
        case 'CmonMaxScale':
            return [CcNodeType.MAXSCALE];
        case 'CmonGarbdHost':
            return [CcNodeType.GARBD];
        case 'CmonHaProxyHost':
            return [CcNodeType.HAPROXY];
        default:
            return [];
    }
}
